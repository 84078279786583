import Modal from 'react-responsive-modal';
import { Buttons } from '../../ui/atoms/Button';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DataTableAlter from '../../ui/organisms/DataTableAlter';
import { useLazyQuery } from '@apollo/client';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { FETCH_PARTNER_ITEM_CONVERSION_RATES } from '../../../queries/CurrencyConversionQueries';
import Loader from '../../../utils/loader';
import { preparePartnerCurrencyConversions } from '../../../helpers/CurrencyPriceHelper.helpers';
import { useDispatch } from 'react-redux';
import { currencyAndCountryCodes } from '../../../utils/currencies';
import { Tab, Tabs } from '../../ui/molecules/Tabs';
import {
    roundToLowerBoundary,
    roundToNearest,
    roundToUpperBoundary,
} from '../../../helpers/valueRoundHelper.helpers';
import { FETCH_PARTNER_LOCAL_CURRENCY_CONVERSION_RATE } from '../../../queries/PartnerQueries';
import { FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_AND_PARTNER_ID } from '../../../queries/ProductQueries';
import { DEAL_PRICES } from '../../../constants/config/constants';
import { RoundOffBoundary, RoundOffPlace } from '../../../types/deals';

interface Props {
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
    partnerId: any;
    itemId: any;
    costPrice: any;
    currency: string;
    dealPaymentMethods: any;
    dealPercentageDetails: any;
}

export default function ViewDealPrices({
    openModal,
    setOpenModal,
    partnerId,
    itemId,
    costPrice,
    currency,
    dealPaymentMethods,
    dealPercentageDetails,
}: Props) {
    const dispatch = useDispatch();
    const [partner, setPartner] = useState(null);
    const [currencyConversions, setCurrencyConversions] = useState([]);
    const [calculatedCurrencyConversions, setCalculatedCurrencyConversions] = useState([]);

    // Partner level local currency conversion rate - fetched on modal load
    const [partnerLocalCurrencyConversionRate, setPartnerLocalCurrencyConversionRate] =
        useState(null);
    // Partner local currency - fetched on modal load
    const [partnerLocalCurrency, setPartnerLocalCurrency] = useState<string>(null);
    // Product level local currency conversion rate
    const [productLocalCurrencyConversionRate, setProductLocalCurrencyConversionRate] = useState(
        partner?.localCurrencyConversionRate,
    );
    // Value in item points
    const [calculatedItemPoints, setCalculatedItemPoints] = useState(null);
    // Price in local currency after applying the mark-up percentage
    const [localCurrencyMarkedUpPrice, setLocalCurrencyMarkedUpPrice] = useState(null);
    // Retail prince in points rounded up to the nearest 10th
    // Conversion rate from product's source currency to partner's local currency - read from currency conversions data
    const [sourceToLocalConversionRate, setSourceToLocalConversionRate] = useState<number>(null);

    const [loadPartner, { data: partnerData, loading: partnerLoading }] = useLazyQuery(
        FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_AND_PARTNER_ID,
        {
            variables: { partnerId: partnerId, itemId: itemId },
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
            onCompleted: () => {
                setPartner(partnerData?.partnerConversionsByItemAndPartnerId);
                loadCurrencyConversions();
            },
        },
    );

    const [
        loadCurrencyConversions,
        { data: partnerCurrencyConversions, loading: partnerCurrencyConversionsLoading },
    ] = useLazyQuery(FETCH_PARTNER_ITEM_CONVERSION_RATES, {
        variables: { partnerId: partner?.partnerId, itemId: itemId },
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onCompleted: () => {
            setCurrencyConversions(partnerCurrencyConversions?.currencyConversions);
        },
    });

    const [
        fetchPartnerLocalCurrencyConversionRate,
        { loading: localCurrencyConversionRateLoading },
    ] = useLazyQuery(FETCH_PARTNER_LOCAL_CURRENCY_CONVERSION_RATE, {
        variables: { partnerId: partner?.id },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        context: { clientName: ClientsEnum.STORE },
        onCompleted(data) {
            if (
                data.partnerLocalCurrencyConversionRate &&
                data.partnerLocalCurrencyConversionRate.length > 0
            ) {
                setPartnerLocalCurrencyConversionRate(
                    data.partnerLocalCurrencyConversionRate[0].conversionRate,
                );
                setPartnerLocalCurrency(data.partnerLocalCurrencyConversionRate[0].localCurrency);
            }
        },
        onError() {
            console.error('Failed to fetch local currency conversion rate');
        },
    });

    const handleWarningOkayClick = () => {
        loadCurrencyConversions();
    };

    const calcuateFinalRetailPrice = (retailPrice) => {
        const retailPriceCalculated =
            calculatedItemPoints > 10
                ? roundToNearest(calculatedItemPoints, 10)?.toFixed(2)
                : Math.ceil(calculatedItemPoints) || 0;

        const finalRetailPrice = retailPrice || retailPriceCalculated;
        return finalRetailPrice;
    };

    const calculateFinalPrice = (finalPriceOverridenValue, markedUpPrice) => {
        return finalPriceOverridenValue ? finalPriceOverridenValue : markedUpPrice;
    };

    const calculateDealPrice = (finalPrice) => {
        const dealPrice =
            finalPrice != 0
                ? (finalPrice * (100 - dealPercentageDetails?.dealPercentage)) / 100
                : 0;

        let roundOffPlace = null;
        if (
            dealPercentageDetails?.finalPriceRoundOffDecimalPlace != null &&
            dealPercentageDetails?.finalPriceRoundOffDecimalPlace != ''
        ) {
            if (
                dealPercentageDetails?.finalPriceRoundOffDecimalPlace ==
                RoundOffPlace[RoundOffPlace.WHOLE_NUMBER]
            ) {
                roundOffPlace = 1;
            } else if (
                dealPercentageDetails?.finalPriceRoundOffDecimalPlace ==
                RoundOffPlace[RoundOffPlace.FIRST_DECIMAL]
            ) {
                roundOffPlace = 10;
            } else if (
                dealPercentageDetails?.finalPriceRoundOffDecimalPlace ==
                RoundOffPlace[RoundOffPlace.SECOND_DECIMAL]
            ) {
                roundOffPlace = 100;
            }
        }

        let roundedDealPrice = dealPrice;

        if (
            dealPercentageDetails?.finalPriceRoundOffBoundary != null &&
            dealPercentageDetails?.finalPriceRoundOffBoundary != '' &&
            roundOffPlace != null
        ) {
            if (
                dealPercentageDetails?.finalPriceRoundOffBoundary ==
                RoundOffBoundary[RoundOffBoundary.UPPER_BOUNDARY]
            ) {
                roundedDealPrice = roundToUpperBoundary(dealPrice, roundOffPlace);
            } else if (
                dealPercentageDetails?.finalPriceRoundOffBoundary ==
                RoundOffBoundary[RoundOffBoundary.LOWER_BOUNDARY]
            ) {
                roundedDealPrice = roundToLowerBoundary(dealPrice, roundOffPlace);
            }
        }

        return roundedDealPrice;
    };

    const currencyTableColumns = useMemo(
        () => [
            {
                Header: 'Source Currency',
                accessor: 'sourceCurrency',
                Cell: function formatCellWithCurrency({ value }) {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Target Currency',
                accessor: 'targetCurrency',
                Cell: function formatCellWithCurrency({ value }) {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Conversion Rate',
                accessor: 'conversionRate',
                Cell: function displayConversionRate({ row, value }) {
                    return value ?? 'Not set';
                },
            },
            {
                id: 'summary',
                accessor: '',
                Cell: function displayConversionRateSummary({ row }) {
                    return row?.original?.conversionRate
                        ? `1 ${row?.original?.sourceCurrency} = ${row?.original?.conversionRate} ${row?.original?.targetCurrency}`
                        : `Currency conversion rate between ${row?.original?.sourceCurrency} and ${row?.original?.targetCurrency} is not set`;
                },
            },
            {
                Header: 'Final Price',
                accessor: 'targetPrice',
            },
            {
                Header: 'Marked-up Price',
                accessor: 'markedUpPriceForCurrency',
            },
            {
                Header: 'Final Marked-up Price',
                accessor: '',
                Cell: function displayFinalPrice({ row }) {
                    const currencyConversion = row?.original;

                    const finalPriceDCB = calculateFinalPrice(
                        currencyConversion?.finalPriceDCBOverridden,
                        currencyConversion?.markedUpPriceForCurrency,
                    );

                    return finalPriceDCB;
                },
            },
            {
                Header: 'Deal Price (-' + dealPercentageDetails?.dealPercentage + '%)',
                accessor: '',
                Cell: function displayDealPriceForDCB({ row }) {
                    const currencyConversion = row?.original;

                    const finalPriceDCB = calculateFinalPrice(
                        currencyConversion?.finalPriceDCBOverridden,
                        currencyConversion?.markedUpPriceForCurrency,
                    );

                    return calculateDealPrice(finalPriceDCB);
                },
            },
        ],
        [
            partner?.markedUpPercentageForCurrency,
            costPrice,
            dealPaymentMethods,
            dealPercentageDetails,
            partnerId,
        ],
    );

    const pointsTableColumns = useMemo(
        () => [
            {
                Header: 'Source Currency',
                accessor: '',
                Cell: function sourceCurrencyCell() {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === currency)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{currency}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Conversion Rate',
                acccessor: '',
                Cell: function displayPointsConversionRateCell() {
                    return sourceToLocalConversionRate;
                },
            },
            {
                Header: 'Target/Local Currency',
                accessor: 'localCurrency',
                Cell: function localCurrencyCell({ value }) {
                    const localCurrency = partnerLocalCurrency || value;
                    if (!localCurrency) return <div></div>;

                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === localCurrency)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{localCurrency}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Price in Local Currency',
                accessor: '',
                Cell: function priceInLocalCurrencyCell() {
                    const localCurrencyPrice = costPrice * sourceToLocalConversionRate;
                    return <div className="text-center">{localCurrencyPrice}</div>;
                },
            },
            {
                Header: 'Marked-up Price',
                accessor: '',
                Cell: function markedUpPriceInLocalCurrencyCell({ row }) {
                    const localCurrencyPrice = costPrice * sourceToLocalConversionRate;
                    const markedUpPercentage = partner?.markedUpPercentage;
                    const markedUpPriceInLocalCurrency = parseFloat(
                        ((localCurrencyPrice * markedUpPercentage) / 100).toFixed(2),
                    );
                    const finalPriceInLocalCurrency =
                        localCurrencyPrice + markedUpPriceInLocalCurrency;

                    // For the marked up price value, depending on the data availability,
                    // or the price fetched from the backend,
                    // or the price we calculate manually.
                    return (
                        localCurrencyMarkedUpPrice ||
                        partner?.markedUpPrice ||
                        finalPriceInLocalCurrency
                    );
                },
            },
            {
                Header: 'Conversion Rate',
                accessor: 'localCurrencyConversionRate',
                Cell: function displayConversionRateCell({ row, value }) {
                    if (localCurrencyConversionRateLoading) {
                        return <div>Loading...</div>;
                    }

                    // For the local currency conversion rate,
                    // or the value fetched from the item_partner_points table.
                    // If both of these are not present, we fallback to the
                    // partner-level local currency conversion rate.
                    return (
                        productLocalCurrencyConversionRate ||
                        value ||
                        partnerLocalCurrencyConversionRate
                    );
                },
            },
            {
                Header: ' ',
                accessor: '',
                Cell: function localCurrencyEquationCell({ row }) {
                    const value = row.values.localCurrencyConversionRate;
                    const localCurrency = partnerLocalCurrency || row?.original?.localCurrency;

                    return (
                        <div className="mx-5">
                            {localCurrency} 1 ={' '}
                            {productLocalCurrencyConversionRate ||
                                value ||
                                partnerLocalCurrencyConversionRate}{' '}
                            PTS
                        </div>
                    );
                },
            },
            {
                Header: 'Item Points',
                accessor: 'itemPoints',
                Cell: function itemPointsCell() {
                    const localCurrencyPrice = costPrice * sourceToLocalConversionRate;
                    const markedUpPercentage = partner?.markedUpPercentage;
                    const markedUpPriceInLocalCurrency = parseFloat(
                        ((localCurrencyPrice * markedUpPercentage) / 100).toFixed(2),
                    );
                    const finalPriceInLocalCurrency =
                        partner?.markedUpPrice || localCurrencyPrice + markedUpPriceInLocalCurrency;

                    const itemPoints =
                        (localCurrencyMarkedUpPrice || finalPriceInLocalCurrency) *
                        (productLocalCurrencyConversionRate || partnerLocalCurrencyConversionRate);
                    setCalculatedItemPoints(itemPoints);
                    return <div className="text-center">{itemPoints.toFixed(2)}</div>;
                },
            },
            {
                Header: 'Retail Price in Points (Rounded)',
                accessor: '',
                Cell: function retailPriceInPointsCell() {
                    const value =
                        calculatedItemPoints > 10
                            ? roundToNearest(calculatedItemPoints, 10)?.toFixed(2)
                            : Math.ceil(calculatedItemPoints) || 0;

                    return <div className="text-center">{value}</div>;
                },
            },
            {
                Header: 'Final Retail Price',
                accessor: '',
                Cell: function finalRetailPriceCell({ row }) {
                    return calcuateFinalRetailPrice(row?.original?.finalRetailPrice);
                },
            },
            {
                Header: 'Deal Price (-' + dealPercentageDetails?.dealPercentage + '%)',
                accessor: '',
                Cell: function displayDealPriceForPonits({ row }) {
                    const finalRetailPrice = calcuateFinalRetailPrice(
                        row?.original?.finalRetailPrice,
                    );

                    return calculateDealPrice(finalRetailPrice);
                },
            },
        ],
        [
            currency,
            sourceToLocalConversionRate,
            partnerLocalCurrency,
            costPrice,
            partner?.markedUpPercentage,
            partner?.markedUpPrice,
            localCurrencyMarkedUpPrice,
            localCurrencyConversionRateLoading,
            productLocalCurrencyConversionRate,
            partnerLocalCurrencyConversionRate,
            calculatedItemPoints,
            dealPercentageDetails,
        ],
    );

    const pspTableColumns = useMemo(
        () => [
            {
                Header: 'Source Currency',
                accessor: 'sourceCurrency',
                Cell: function formatCellWithCurrency({ value }) {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Target Currency',
                accessor: 'targetCurrency',
                Cell: function formatCellWithCurrency({ value }) {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Conversion Rate',
                accessor: 'conversionRate',
                Cell: function displayConversionRate({ row, value }) {
                    return value ?? 'Not set';
                },
            },
            {
                id: 'summary',
                accessor: '',
                Cell: function displayConversionRateSummary({ row }) {
                    return row?.original?.conversionRate
                        ? `1 ${row?.original?.sourceCurrency} = ${row?.original?.conversionRate} ${row?.original?.targetCurrency}`
                        : `Currency conversion rate between ${row?.original?.sourceCurrency} and ${row?.original?.targetCurrency} is not set`;
                },
            },
            {
                Header: 'Final Price',
                accessor: 'targetPrice',
            },
            {
                Header: 'Marked-up Price',
                accessor: 'markedUpPriceForPsp',
            },
            {
                Header: 'Final Marked-up Price',
                accessor: '',
                Cell: function editFinalPrice({ row }) {
                    const currencyConversion = row?.original;

                    return calculateFinalPrice(
                        currencyConversion?.finalPricePSPOverridden,
                        currencyConversion?.markedUpPriceForPsp,
                    );
                },
            },
            {
                Header: 'Deal Price (-' + dealPercentageDetails?.dealPercentage + '%)',
                accessor: '',
                Cell: function displayDealPriceForPonits({ row }) {
                    const currencyConversion = row?.original;

                    const finalPriceForPsp = calculateFinalPrice(
                        currencyConversion?.finalPricePSPOverridden,
                        currencyConversion?.markedUpPriceForPsp,
                    );

                    return calculateDealPrice(finalPriceForPsp);
                },
            },
        ],
        [partner?.markedUpPercentageForPsp, costPrice, dealPercentageDetails],
    );

    const handlePrepareConversionRates = useCallback(() => {
        if (currencyConversions && costPrice) {
            const results = preparePartnerCurrencyConversions(
                costPrice,
                currencyConversions,
                partner?.markedUpPercentageForCurrency,
                partner?.markedUpPercentage,
                partner?.markedUpPercentageForPsp,
            );
            setCalculatedCurrencyConversions(results);
        }
    }, [costPrice, currencyConversions, partner]);

    const handleModalClose = () => {
        setLocalCurrencyMarkedUpPrice(null);
        setProductLocalCurrencyConversionRate(partner?.localCurrencyConversionRate);
        setPartnerLocalCurrency(null);
        setPartnerLocalCurrencyConversionRate(null);
        setOpenModal(false);
    };

    useEffect(() => {
        loadPartner();
        loadCurrencyConversions();
    }, [loadCurrencyConversions, loadPartner]);

    useEffect(() => {
        if (currencyConversions) {
            handlePrepareConversionRates();
        }
    }, [currencyConversions, handlePrepareConversionRates]);

    useEffect(() => {
        if (partner) {
            setProductLocalCurrencyConversionRate(partner?.localCurrencyConversionRate);
        }
    }, [partner]);

    useEffect(() => {
        if (partner) {
            fetchPartnerLocalCurrencyConversionRate();
        }
    }, [fetchPartnerLocalCurrencyConversionRate, partner]);

    // Find and set the conversion rate between source and local currency
    useEffect(() => {
        if (currencyConversions.length > 0 && partnerLocalCurrency !== null) {
            const sourceToLocalConversion = currencyConversions.find((con) => {
                if (
                    con.sourceCurrency === currency &&
                    con.targetCurrency === partnerLocalCurrency &&
                    con.conversionRate !== null
                ) {
                    return true;
                }

                return false;
            });

            if (sourceToLocalConversion) {
                setSourceToLocalConversionRate(sourceToLocalConversion.conversionRate);
            }
        }
    }, [currency, currencyConversions, partnerLocalCurrency]);

    // PartnerInfo section is common to both of the tabs
    const PartnerInfo = () => (
        <>
            <div className="flex flex-row gap-2 justify-between mb-4">
                <div className="col">
                    <p className="font-poppins font-semibold text-purple-600">
                        Partner Name: {partner?.partnerName}
                    </p>
                </div>
                <div className="col text-right">
                    <p className="font-poppins font-semibold text-purple-600">
                        Cost: {costPrice?.toFixed(2)} {` ` + currency}{' '}
                        <i
                            className={`${currencyAndCountryCodes
                                .find((x) => x.currency === currency)
                                ?.country.toLowerCase()} flag px-5 pt-1`}
                        ></i>
                    </p>
                </div>
            </div>
            <div className="w-full mb-4">
                <p className="font-poppins font-semibold text-purple-600">
                    Partner ID: {partner?.partnerId}
                </p>
            </div>
        </>
    );

    return (
        <Modal
            open={openModal}
            onClose={() => {
                handleModalClose();
            }}
            center={true}
            styles={{ modal: { borderRadius: 8, maxWidth: '80%' } }}
            focusTrapped={false}
        >
            <div className="flex flex-row p-3 mb-3 justify-center">
                <h3 className="text-xl font-poppins font-semibold flex">
                    View Conversions & Prices
                </h3>
            </div>
            {/* <div className="relative w-full">
                {showToast && (
                    <Toast
                        setShowToast={setShowToast}
                        message={message}
                        selfDestruct={true}
                        selfDestructTimer={1000}
                        margin={0}
                        error={toastError}
                        style={'relative w-full z-5 h-7 mb-0 -mt-0'}
                    />
                )}
            </div> */}
            <div className="pt-4">
                <Tabs tabType={DEAL_PRICES}>
                    <Tab isDisabled={!dealPaymentMethods?.isDcbSelected} label="Currencies - DCB">
                        <>
                            <PartnerInfo />
                            <div className="flex justify-between flex-row items-center font-poppins text-gray-500 mb-5">
                                <div className="flex gap-3 items-center">
                                    Marked-up Percentage (%)
                                    <div>{partner?.markedUpPercentageForCurrency}</div>
                                </div>
                            </div>
                            {partnerCurrencyConversionsLoading ? (
                                <div className="w-full">
                                    <div className="m-auto flex flex-col ">
                                        <div className="m-auto p-10">
                                            <Loader />
                                        </div>
                                        <div className="p-10 bg-purple-100"></div>
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full border rounded-lg">
                                    {calculatedCurrencyConversions?.length > 0 ? (
                                        <DataTableAlter
                                            columns={currencyTableColumns}
                                            data={calculatedCurrencyConversions}
                                            hidePagination={true}
                                            customizedPageSize={
                                                calculatedCurrencyConversions?.length
                                            }
                                            pageCount={1}
                                        />
                                    ) : (
                                        <>
                                            <div className="m-auto flex flex-col ">
                                                <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                                    {currencyTableColumns?.map((column, i) => {
                                                        return (
                                                            <div
                                                                className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                                key={i}
                                                            >
                                                                {column?.Header}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div className="m-auto p-10">No data found.</div>
                                                <div className="p-10 bg-purple-100"></div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    </Tab>
                    <Tab isDisabled={!dealPaymentMethods?.isPointsSelected} label="Points">
                        <div>
                            <PartnerInfo />
                            <div className="flex gap-3 flex-row items-center font-poppins text-gray-500 mb-5">
                                Marked-up Percentage (%)
                                <div>{partner?.markedUpPercentage}</div>
                            </div>
                            <div>
                                {partnerLocalCurrency || partner?.localCurrency ? (
                                    <DataTableAlter
                                        columns={pointsTableColumns}
                                        data={[partner]}
                                        hidePagination={true}
                                        pageSizes={1}
                                        currentPage={1}
                                    />
                                ) : (
                                    <div className="m-auto flex flex-col">
                                        <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                            {pointsTableColumns.map((col, index) => (
                                                <div
                                                    className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                    key={index}
                                                >
                                                    {col.Header}
                                                </div>
                                            ))}
                                        </div>
                                        <div className="m-auto p-10 flex text-purple-500">
                                            Partner local currency is not set
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Tab>
                    <Tab isDisabled={!dealPaymentMethods?.isPspSelected} label="Currencies - PSP">
                        <>
                            <PartnerInfo />
                            <div className="flex justify-between flex-row items-center font-poppins text-gray-500 mb-5">
                                <div className="flex gap-3 items-center">
                                    Marked-up Percentage (%)
                                    <div>{partner?.markedUpPercentageForPsp}</div>
                                </div>
                            </div>
                            {partnerCurrencyConversionsLoading ? (
                                <div className="w-full">
                                    <div className="m-auto flex flex-col ">
                                        <div className="m-auto p-10">
                                            <Loader />
                                        </div>
                                        <div className="p-10 bg-purple-100"></div>
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full border rounded-lg">
                                    {calculatedCurrencyConversions?.length > 0 ? (
                                        <DataTableAlter
                                            columns={pspTableColumns}
                                            data={calculatedCurrencyConversions}
                                            hidePagination={true}
                                            customizedPageSize={
                                                calculatedCurrencyConversions?.length
                                            }
                                            pageCount={1}
                                        />
                                    ) : (
                                        <>
                                            <div className="m-auto flex flex-col ">
                                                <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                                    {pspTableColumns?.map((column, i) => {
                                                        return (
                                                            <div
                                                                className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                                key={i}
                                                            >
                                                                {column?.Header}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div className="m-auto p-10">No data found.</div>
                                                <div className="p-10 bg-purple-100"></div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    </Tab>
                </Tabs>

                <div className="flex flex-row justify-end pt-4">
                    <Buttons
                        name="Close"
                        type="submit"
                        buttonType="secondary"
                        id="okay"
                        size="e-small"
                        onclick={handleModalClose}
                        padding="py-3"
                    />
                </div>
            </div>
        </Modal>
    );
}
